import {useState} from 'react';
import {IoPersonCircleOutline} from 'react-icons/io5';
import {FaPhoneAlt} from 'react-icons/fa';
import {MdEmail} from 'react-icons/md';

const Home = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {name, email, message} = formData;
    const emailContent = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;

    const mailtoLink = `mailto:developinger1@gmail.com?subject=Enquiry&body=${encodeURIComponent(
      emailContent
    )}`;

    window.location.href = mailtoLink;

    setFormData({
      name: '',
      email: '',
      message: '',
    });
  };

  return (
    <>
      <section id="hero">
        <div class="home-container">
          <h1>Welcome to Developinger</h1>
          <hr className="divider my-4" />
          <p>Your partner in web development success</p>
          <a href="/#abouts">
            <button class="round-button">
              <h3>FINE OUT MORE</h3>
            </button>
          </a>
        </div>
      </section>
      <section id="abouts">
        <div class="about-container">
          <h2>Your Destination to Make Your Business Dreams Come True.</h2>
          <hr className="divider my-4" />
          <p>
            "At Developinger, we craft digital experiences that blend
            creativity, innovation, and precision to help you succeed in the
            ever-evolving digital landscape."
          </p>
          <a href="/#services">
            <button class="round-button">
              <h3>GET STARTED!</h3>
            </button>
          </a>
        </div>
      </section>

      <section id="services">
        <h2>Our Services</h2>
        <hr className="divider my-4" />
        <div class="container">
          <div class="service">
            <img
              src="https://media.istockphoto.com/id/1327278358/photo/website-design-word-abstract-in-wood-type.jpg?s=612x612&w=0&k=20&c=AmfkYW4EhI1V0ngtPgbYzoNCIycp3ty3_QCMhoMveWQ="
              alt="Web Design"
            />
            <h3>Web Design</h3>
          </div>
          <div class="service">
            <img
              src="https://media.istockphoto.com/id/1305026697/photo/web-development-inscription-against-laptop-and-code-background-learn-web-development.jpg?s=612x612&w=0&k=20&c=cQZ5Lu6oLkrF5SV-eZx-bsnyKqGy6wp8N3SLkORpkqQ="
              alt="Web Development"
            />
            <h3>Web Development</h3>
          </div>
          <div class="service">
            <img
              src="https://media.istockphoto.com/id/1388935730/photo/neon-seo-sign-on-black.jpg?s=612x612&w=0&k=20&c=h2TWScyxDGSPypDY0RChxJYrOpTJxzjEfkVvjbuHOS8="
              alt="SEO Optimization"
            />
            <h3>SEO Optimization</h3>
          </div>
          <div class="service">
            <img
              src="https://media.istockphoto.com/id/935427102/photo/digital-marketing-word-on-blackboard-with-supportive-icons.jpg?s=612x612&w=0&k=20&c=eM94iufXxJqzEJ6p5caJOgyxOZYfrluNV950vSXc2ks="
              alt="Digital Marketing"
            />
            <h3>Digital Marketing</h3>
          </div>
        </div>
      </section>

      <section id="portfolio">
        <h2>Our Portfolio</h2>
        <hr className="divider my-4" />
        <div class="portfolio-container">
          <div class="portfolio-item">
            <img
              className="image"
              src="https://media.istockphoto.com/id/172367908/photo/small-airport.jpg?s=612x612&w=0&k=20&c=EieAoE4zHFrhgjqVf2227xE5664C2eFQEssNVCMJTO4="
              alt="Portfolio 1"
            />
            <div className="middle">
              <div className="text">Eva Aviation Academy</div>
            </div>
          </div>
          <div class="portfolio-item">
            <img
              className="image"
              src="https://media.istockphoto.com/id/504862904/photo/container-ship-in-the-harbor-in-asia.jpg?s=612x612&w=0&k=20&c=22ntYAlFmYlRVBBgw3sTiivV4Xx66SPEaa186sMlb-s="
              alt="Portfolio 2"
            />
            <div className="middle">
              <div className="text">Eva Aviation Import & Export</div>
            </div>
          </div>
          <div class="portfolio-item">
            <img
              className="image"
              src="https://media.istockphoto.com/id/863315142/photo/spices-at-the-spice-market-in-istanbul.jpg?s=612x612&w=0&k=20&c=lUje03QFKRg3iJ_UyOg065CDCoAST8Pyej2rY_tmHBI="
              alt="Portfolio 3"
            />
            <div className="middle">
              <div className="text">Adishivshakti Enterprises</div>
            </div>
          </div>
          <div class="portfolio-item">
            <img
              className="image"
              src="https://media.istockphoto.com/id/846489122/photo/responsive-web-design-website-wireframe-sketch-layout-on-computer-screen.jpg?s=612x612&w=0&k=20&c=mtPGkXm8Sj9AHcg1pEAF8tAdSpn-gRyb-UJnpsYn8bI="
              alt="Portfolio 4"
            />
            <div className="middle">
              <div className="text">Developinger -Web Development</div>
            </div>
          </div>
        </div>
      </section>

      <section id="team">
        <h2>Meet the Team</h2>
        <hr className="divider my-4" />
        <div class="container">
          <div class="team-member">
            <IoPersonCircleOutline className="persion-icon" />
            <h3>Sandeep Kumar</h3>
            <p>Lead Web Developer & Design</p>
            <p>4Years+ Experience in Web Devlopment </p>
          </div>
          <div class="team-member">
            <IoPersonCircleOutline className="persion-icon" />
            <h3>Rakesh Kumar</h3>
            <p>Web Developer Expert</p>
            <p>4Years + Experience in Web Designing </p>
          </div>
          <div class="team-member">
            <IoPersonCircleOutline className="persion-icon" />
            <h3>Sanjeev Suman</h3>
            <p>Web Developer Expert </p>
            <p>3Years+ Experience in web Development</p>
          </div>
          <div class="team-member">
            <IoPersonCircleOutline className="persion-icon" />

            <h3>Abhishek Singh</h3>
            <p>SEO & Digital Marketing Expert </p>
            <p>5Years+ Experience in SEO & Digital Marketing </p>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="info">
          <h1>Let's Get In Touch!</h1>
          <hr className="divider my-4" />
          <p>
            Ready to start your next project with us? Give us a call or send us
            an email and we will get back to you as soon as possible!
          </p>
        </div>
        <div className="contact-section">
          <div className="contact-detail">
            <FaPhoneAlt className="react-icon" />
            <p className="mobile">+91 8319944822</p>
          </div>
          <div className="contact-detail">
            <MdEmail className="react-icon" />
            <p className="email">
              <a href="mailto:developinger1@gmail.com">
                developinger1@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="enquiry-form-container">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit} className="enquiry-form">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="4"
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </section>
    </>
  );
};
export default Home;
