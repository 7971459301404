/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {AiOutlineMenu, AiOutlineClose} from 'react-icons/ai';

import '../style/Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`mainhome-header ${isMenuOpen ? 'menu-open' : ''} ${
        scrolled ? 'scrolled' : ''
      }`}
    >
      <div className="mainhome-logo">
        <img src="../images/logo.png" alt="" srcset="" />
        <h1>Developinger</h1>
      </div>
      <nav className={`mainhome-nav-links ${isMenuOpen ? 'open' : ''}`}>
        <a href="/#hero">Home</a>
        <a href="/#abouts">About</a>
        <a href="/#services">Services</a>
        <a href="/#portfolio">Portfolio</a>
        <a href="/#team">Team</a>
        <a href="/#contact">Contact</a>
      </nav>
      <div className="mainhome-menu-icon" onClick={toggleMenu}>
        {isMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
      </div>
    </header>
  );
};

export default Header;
