const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer>
        <div class="container">
          <p>
            Copyright &copy; 2023-{currentYear} www.developinger.in . All rights
            reserved.
          </p>
        </div>
      </footer>
    </>
  );
};
export default Footer;
